import React from 'react';
import Center from '../components/utilities/Center';

const PrivacyPolicy = () => {
  return (
    <Center className="">
      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
      <p className="mb-4">
        For the purpose of these Terms and Conditions, The term "we", "us", "our" used anywhere on this page shall mean FORTUNE GATE ORGANIC FARMING, whose registered/operational office is 6/3 A, ERAVATHUR, ERAVATHUR P.O., Thrissur, Kerala, 680734 Thrissur KERALA 680734. "you", “your”, "user", “visitor” shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.
      </p>
      <p className="mb-4">
        Your use of the website and/or purchase from us are governed by following Terms and Conditions:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>The content of the pages of this website is subject to change without notice.</li>
        <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
        <li>Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements.</li>
        <li>Our website contains material which is owned by or licensed to us. This material includes, but are not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
        <li>All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
        <li>Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.</li>
        <li>From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.</li>
        <li>You may not create a link to our website from another website or document without FORTUNE GATE ORGANIC FARMING’s prior written consent.</li>
        <li>Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India.</li>
        <li>We shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-8 mb-4">Cancellation and Refund</h2>
      <p className="mb-4">
        FORTUNE GATE ORGANIC FARMING believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Cancellations will be considered only if the request is made within same day of placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.</li>
        <li>FORTUNE GATE ORGANIC FARMING does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.</li>
        <li>In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within same day of receipt of the products.</li>
        <li>In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within same day of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.</li>
        <li>In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.</li>
        <li>In case of any Refunds approved by the FORTUNE GATE ORGANIC FARMING, it’ll take 6-8 days for the refund to be processed to the end customer.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-8 mb-4">Shipping and Delivery</h2>
      <p className="mb-4">
        For International buyers, orders are shipped and delivered through registered international courier companies and/or International speed post only. For domestic buyers, orders are shipped through registered domestic courier companies and /or speed post only. Orders are shipped within 5-7 days or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject to Courier Company / post office norms. For international buyers, the shipping and delivery timelines may vary depending on product availability at the nearest location. Generally, delivery can take between 10 to 20 days or as specified in the delivery date confirmed at the time of order placement. FORTUNE GATE ORGANIC FARMING is not liable for any delay in delivery by the courier company / postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 5-7 days from the date of the order and payment or as per the delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during registration. For any issues in utilizing our services you may contact our helpdesk on +918078311510 or fortunegate@navjeevana.com.
      </p>
    </Center>
  );
};

export default PrivacyPolicy;
